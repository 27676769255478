
import { Component, Vue } from "vue-property-decorator";
import { Base64 } from "js-base64";

// import Navbar from "@/components/Utility/Navbar.vue";
// import Footer from "@/components/Utility/Footer.vue";
import marked from "marked";
import NewsPage from 'fdtigermaster-client-sdk/lib/src/News/NewsPage';
import INewsPage from 'fdtigermaster-client-sdk/lib/src/Entity/INewsPage';

@Component({
  components: {
    // Navbar,
    // Footer,
  },
})
export default class News extends Vue {
  //data
  markdownText="";
  newsObj:INewsPage={
    id: "",
    title: "",
    content: "",
    link: "",
}

  //hook
  created(): void {
    this.getNews();
  }
  //computed
  get markdownToHtml(): string {
    return marked(this.markdownText);
  }
  //methods
  async getNews(): Promise<void> {
    let newspageid = this.$route.query.newsPageId as string
     const news = new NewsPage()
     this.newsObj = await news.get(newspageid)
     this.markdownText = Base64.decode(this.newsObj.content as string);
  }
  checkDate(data: string): string {
    return data.substr(0, 10);
  }
}
